.Test_Report-container{
    .Test_Report-item-img{
        width: 100%;
        height: 335px;
        margin-bottom: 5%;
        background-position: center center;
        background-size: 1920px 335px;
        background-repeat: no-repeat;
    }
    .Test_Report-item-dwon{
        margin-left:10%;
        margin-bottom: 15%;
        text-align:left;
        h2{
            text-align:left;
            font-weight:600;
            color: #007c45;
            font-size: 2.5em;
            margin-bottom: 1%;
        }
        a{
            font-size: 2em;
            color: #707070;
            &:hover{
                color: #74B247;
                text-decoration:underline;
            }
        }
        div{
            margin-bottom: 5%;
        }
    }
    @media all and (min-width: 480px) and (max-width: 959px) {
        .Test_Report-item-img{
            overflow: hidden;
            height: 200px;
        }
        .Test_Report-item-dwon{
            margin-left: 2%;
            margin-right: 2%;
            margin-bottom: 10%;
            h2{
                font-size: 2em;
            }
            a{
                font-size: 1.25em;
            }
            div{
                margin-bottom: 2.5%;
            }
       }
    }
    @media all and (max-width: 450px) {
        .Test_Report-item-img{
            height: 175px;
        }
        .Test_Report-item-dwon{
            margin-left:1%;
            margin-right:1%;
            margin-bottom: 5%;
            h2{
                font-size: 1.5em;
            }
            a{
                font-size: 1.15em;    
            }
            div{
                margin-bottom: 2%;
            }
        }
        
    }
}
