.product-home-container {
    width: 96%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 81px - 228px);
    border: none;
    margin: 20px auto 20px auto;
  

    .product-item{
      width: 30%;
      display: block;
      max-width: 300px;
      min-width: 300px;
    }
}