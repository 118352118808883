.image-modal-container {
    cursor: zoom-out;
    display: none;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;

    .main-panel {
        margin: 10vh auto;
        z-index: 10001;
        animation: blowUpModal 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
}

@keyframes blowUpModal {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}