@import "./LayoutParameter.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.font-dark-green{
	color:#007c45;
}

.font-light-green{
	color: #74b247;
}

.font-white{
	color: white;
}

* {
	margin: 0;
	padding: 0;
}
li {
	list-style: none;
}
.menu-item {
	color: #007c45;
	text-decoration: none;
	display: block;
	padding: 10px 20px 10px 20px;
	height: 100%;
	font-size: 16px;
	&:hover {
		color: #D2691E;
	}
}
.menu {
	transition: linear height 0.1s;
}

.menu-mask {
	display: none;
}
.language-select {
	font-size: 16px;
	outline: none;
	height: 40px;
	border: none;
	background-color: white;
	color: #007c45;
	&:hover {
		cursor: pointer;
	}
}
.footer-link {
	padding-left: 5px;
}
.language-icon {
	font-size: 16px;
	line-height: 5px;
}
.language-section {
	display: flex;
	align-items: center;
	justify-content: center;
}
.copyright {
	width: 100%;
	font-size: 14px;
	color: white;
	padding-bottom: 15px;
	background-color: #3CB371;
}
.right-footer-section {
	ul {
		li {
			a {
				&:hover {
					text-decoration: underline white;
				}
			}
		}
	}
}
@media only screen and (min-width: $middle-media) {
    .main-section {
        margin: 0px auto 0px auto;
        min-height: $big-layout-min-main;
        width: 100%;
		background-color: white;
    }
	.nav-bar-section {
		width: 100%;
		height: $big-nav-bar-height;
		background-color: white;
		position: fixed;
		top: 0px;
		left: 0px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 1000;
		.menu-collapse {
			display: none;
		}
		.menu-collapse-icon {
			display: none;
		}
		.menu {
			display: flex;
			align-items: center;
			padding-right: 30px;
			margin-bottom: 0;
		}
	}
	.nav-log-img {
		height: 40px;
		padding-left: 15px;
	}
	.nav-bar-margin {
		width: 100%;
		height: 80px;
	}
	.footer-bar-section {
		height: $footer-bar-height;
		width: 100%;
		border-top: 1px solid white;
		background-color: #3CB371;
		display: flex;
		justify-content: space-between;
	}
	.left-footer-section {
		width: 100%;
		margin: 5px 0px 5px 0px;
		padding: 15px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: left;
		ul {
			li {
				word-wrap: break-word;
				width: 100%;
				font-size: 14px;
				padding-top: 2px;
				text-align: left;
				color: white;
				display: flex;
				align-items: center;
			}
		}
	}
	.right-footer-section {
		width: 100%;
		padding: 15px 30px 15px 0px;
		ul {
			li {
				text-align: right;
				padding: 2px 4px;
				a {
					text-decoration: none;
					font-size: 14px;
					color: white;
				}
			}
		}
	}
	.footer-img {
		margin-right: 30px;
		height: 70px;
	}
}

@media only screen and (max-width: $middle-media) {
    .main-section {
        margin: 0px auto 0px auto;
        min-height: $small-layout-min-main;
        width: 100%;
		background-color: white;

    }
	.menu-mask {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: gray;
		opacity: 0.6;
		display: none;
		z-index: 1000;
	}
	.nav-bar-section {
		width: 100%;
		height: $small-nav-bar-height;
		background-color: white;
		position: fixed;
		top: 0px;
		left: 0px;
		overflow: hidden;
		z-index: 1000;
		.menu-collapse {
			position: absolute;
			top: 15px;
			left: 15px;
			height: 40px;
			width: 40px;
			opacity: 0.1;
			opacity: 0;
			&:hover {
				cursor: pointer;
			}
			&:checked {
				~ {
					.menu {
						display: block;
						height: $menu-height;
					}
				}
			}
		}
		.menu-collapse-icon {
			position: absolute;
			top: 15px;
			left: 15px;
			font-size: 35px;
			pointer-events: none;
			color: #007B47;
		}
		.menu {
			position: fixed;
			top: 0px;
			left: 0px;
			width: 100%;
			border: 0px;
			height: 0px;
			overflow: hidden;
			z-index: 2002;
			margin-top: 70px;
			background-color: white;
			margin-bottom: 0;
		}
	}
	.nav-log-img {
		position: absolute;
		top: 15px;
		right: 15px;
		height: 40px;
	}
	.nav-bar-margin {
		width: 100%;
		height: 70px;
	}
	.footer-bar-section {
		height: $footer-bar-height;
		width: 100%;
		background-color: #3CB371;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	.left-footer-section {
		padding: 15px 15px 0px 15px;
		width: 100%;
		ul {
			display: flex;
			flex-wrap: wrap;
			li {
				word-wrap: break-word;
				text-align: left;
				display: flex;
				align-items: center;
				font-size: 13px;
				padding: 2px 10px;
				color: white;
			}
		}
	}
	.right-footer-section {
		padding: 10px 0px 25px 15px;
		width: 100%;
		ul {
			display: flex;
			flex-wrap: wrap;
			li {
				a {
					font-size: 13px;
					padding: 2px 10px;
					text-align: left;
					display: flex;
					align-items: center;
					color: white;
					text-decoration: none;
				}
			}
		}
	}
	.footer-img {
		display: none;
	}
}
@media only screen and (min-width: 1280px) {
	.menu-item {
		font-size: 18px;
	}
	.language-select {
		font-size: 18px;
	}
	.language-icon {
		font-size: 18px;
	}
	.nav-log-img {
		height: 50px;
	}
}
