
.skillsupport-container{
    background-color: white;
    .skillsupport-item-upside{
        width: 100%;
        height: 335px;
        margin-top: 1%;
        text-align: center;
        background-position: center center;
        background-size: 1920px 335px;
        background-repeat: no-repeat;
        img{
            width: 100%;
            height: auto;
            margin: 0px 0px 10px 0px;
        }
    }
    .skillsupport-item-dwon{
        width: 100%;
        margin-top:5%;
        margin-bottom: 7.5%; 
        .skillsupport-item-dwon-img{
            width: 70%;
            margin: 0 15% 0 15%;
            display: flex;
            justify-content: space-between;
            .skillsupport-item-title{
                width: 100%;
                text-align: center;
                font-weight:900;
                white-space: nowrap;
                font-size:xx-large;
                color: #007c45;
                margin-top: 35px;
            }
            div{
                -webkit-transition: -webkit-transform 0.5s ease;
                -moz-transition: -moz-transform 0.5s ease;
                -o-transition: -o-transform 0.5s ease;
                transition: transform 0.5s ease;
                &:hover{
                    -webkit-transform: scale(1.1);
                    -moz-transform: scale(1.1);
                    -o-transform: scale(1.1);
                    transform: scale(1.1);
                }
            }
        }
        a{
            display: flex;
            justify-content: center;
        }

    }
    @media all and (min-width: 580px) and (max-width: 959px) {
        .skillsupport-item-upside{
            height: 250px;
            overflow: hidden;
            img{
                width:auto;
                height: 100%;
            }
        }
        
        .skillsupport-item-dwon{
            margin-top: 5%;
            .skillsupport-item-dwon-img{
                flex-direction:column; 
                .skillsupport-item-icon{
                    width:150px;
                    height:150px;
                }
                .skillsupport-item-title{
                    margin-bottom: 35px;
                    font-size: 2.5em;
                }
            
            }
        }
    }
    @media all and (max-width: 579px) {
        .skillsupport-item-upside{
            height: 200px;
            overflow: hidden;
            img{
                width:auto;
                height: 100%;
            }
        }
        
        .skillsupport-item-dwon{
            margin-top: 10%;
            .skillsupport-item-dwon-img{
                flex-direction:column; 
                .skillsupport-item-icon{
                    width:100px;
                    height:100px;
                }
                .skillsupport-item-title{
                    margin-bottom: 20px;
                    font-size: 2em;
                }
            
            }
        }
    }
}

/* 
.skillsupport-item-dwon-img div:nth-child(1):hover,.skillsupport-item-dwon-img div:nth-child(2):hover,.skillsupport-item-dwon-img div:nth-child(3):hover{
    transform: scale(0.8);
} 
.skillsupport-item-dwon-img{
    display:flex;
    text-align: center;
}
.skillsupport-item-dwon-img img{
    padding-bottom:15%;
}
.skillsupport-item-title-1,.skillsupport-item-title-2,.skillsupport-item-title-3{
    font-weight:900;
    white-space: nowrap;
    font-size:xx-large;
    color: #007c45;
    text-align: center;
}

.skillsupport-item-dwon-img div:nth-child(1){  
    width: 15%; 
    margin-right:5%; 
    margin-left:17.5%; 
} 
.skillsupport-item-dwon-img div:nth-child(2){ 
    width: 15%;
    margin-right:5%; 
    margin-left:5%;  
} 
.skillsupport-item-dwon-img div:nth-child(3){  
    width: 15%;
    margin-right:17.5%; 
    margin-left:5%;
}
.skillsupport-item-dwon-img div:nth-child(1):hover,.skillsupport-item-dwon-img div:nth-child(2):hover,.skillsupport-item-dwon-img div:nth-child(3):hover{
    transform: scale(0.8);
} 
.skillsupport-item-dwon-img div:nth-child(1){ 
        width: 50%;
        margin-right:25%; 
        margin-left:25%;
        margin-bottom:5%; 
    } 
    .skillsupport-item-dwon-img div:nth-child(2){  
        width: 50%;
        height: 25%;
        margin-right:25%; 
        margin-left:25%;
        margin-bottom:5%; 
    } 
    .skillsupport-item-dwon-img div:nth-child(3){  
        width: 50%;
        height: 25%;
        margin-right:25%; 
        margin-left:25%;
    }
*/
