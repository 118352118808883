.linoleum_indoor-container{
    width: 100%;
    background-color:white;
    margin: 0 auto 0 auto;
    .linoleum_indoor-item-1{
        width: 100%;
        height: 758px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        background-image: url("../../../images/IndoorSheetCoverMap.png");
        background-color: white;
        background-size: 1919px 758px;
        background-position: center center;
        background-repeat: no-repeat;
        .title-font-black{
            font-weight: bold;
            font-size: 8em;

        }
        .title-2{
            font-weight: bold;
            font-size:6em;
            color: #707070;
        }
    }
    .linoleum_indoor-item-2{
        width: 100%;
        height: auto;
        .title-content-up{
            background-color:white;
            height: 15%;
            margin: 5% 0;
            text-align: center;
            .title-font-black{
                font-weight: bold;
                font-size: 5em;
            }
        }
       .title-content-down{
           display: flex;
           justify-content:space-around;
           .title-content-right{
            width: 42.5%;
            img{
                margin: 0 2.5% 0 5%;
                width: 100%;
            }
           }
           .title-content-left{
            width: 42.5%;
            img{
                margin: 0 5% 0 2.5%;
                width: 100%;
            }
           }
       }
    }
    .linoleum_indoor-item-3{
        width: 100%;
        margin: 10% 0 5% 0;
        .title-content{
            .title-font-black{
                margin-bottom: 5%;
                text-align: center;
                font-weight: bold;
                font-size: 5em;
            }
            h3{
                margin: 0 15%;
                text-align: left;
                font-weight: bold;
                font-size: 3em;
                color:#535353;
            }
        }
    }
    .linoleum_indoor-item-4{
        .linoleum_indoor-title-content-up{
           img{
            width: 90%;
            text-align: center;
           }
        }
        .title-content{
            height: 15%;
            text-align: center;
            .title-font-black{
                font-weight: bold;
                margin: 5% 0;
                font-size: 5em;
            }
        }

    }
    .linoleum_indoor-item-5{
        .linoleum_indoor-title-content-up{
           img{
            width: 90%;
            text-align: center;
           }
        }
        .title-content{
            height: 15%;
            text-align: center;
            .title-font-black{
                font-weight: bold;
                margin: 5% 0;
                font-size: 5em;
            }
        }

    }
    @media only screen and (min-width: 700px) and (max-width: 1050px) {
        .linoleum_indoor-item-1{
            .title-font-black{
                font-size: 7em;
            }
            .title-2{
                font-size:5em;
            }
        }
        .linoleum_indoor-item-2{
            .title-content-up{
                .title-font-black{
                    font-size:3em;
                }
            }
        }
        .linoleum_indoor-item-3{
            .title-content{
                .title-font-black{
                    font-size: 3em;
                }
                h3{
                    margin: 0 5%;
                    font-size: 2em;
                }
            }
        }
        .linoleum_indoor-item-4{
            .title-content{
                .title-font-black{
                    font-size: 4em;
                }
            }
        }
        .linoleum_indoor-item-5{
            .title-content{
                .title-font-black{
                    font-size: 4em;
                }
            }
        }
    }
    @media all and (min-width: 450px)and (max-width: 699px) {
        .linoleum_indoor-item-1{
            height: 300pt;
            overflow: hidden;
            .title-font-black{
                font-size: 4em;
            }
            .title-2{
                font-size:3em;
            }
        }
        .linoleum_indoor-item-2{
            .title-content-up{
                .title-font-black{
                    font-size:2em;
                }
            }
        }
        .linoleum_indoor-item-3{
            .title-content{
                .title-font-black{
                    font-size: 2em;
                }
                h3{
                    margin: 0 5%;
                    font-size: 1.5em;
                }
            }
        }
        .linoleum_indoor-item-4{
            .title-content{
                .title-font-black{
                    font-size: 3em;
                }
            }
        }
        .linoleum_indoor-item-5{
            .title-content{
                .title-font-black{
                    font-size: 3em;
                }
            }
        }
    }
    @media all and (max-width: 450px) {
        .linoleum_indoor-item-1{
            height: 150pt;
            overflow: hidden;
            .title-font-black{
                font-size: 2.5em;
            }
            .title-2{
                font-size:2em;
            }
        }
        .linoleum_indoor-item-2{
            .title-content-up{
                .title-font-black{
                    font-size:1.75em;
                }
            }
            .title-content-down{
                flex-direction: column;
                .title-content-right{
                 width: 100%;
                 img{
                     margin: 0;
                     width: 100%;
                 }
                }
                .title-content-left{
                 width: 100%;;
                 img{
                     margin: 0;
                     width: 100%;
                 }
                }
            }
        }
        .linoleum_indoor-item-3{
            margin: 5% 0;
            .title-content{
                .title-font-black{
                    font-size: 1.4em;
                }
                h3{
                    margin: 0 5%;
                    font-size: 1em;
                }
            }
        }
        .linoleum_indoor-item-4{
            .title-content{
                .title-font-black{
                    font-size: 2em;
                }
            }
        }
        .linoleum_indoor-item-5{
            .title-content{
                .title-font-black{
                    font-size: 2em;
                }
            }
        }
    }
}