.QamdA-container{
    .QamdA-item-img{
        width: 100%;
        height: 335px;
        margin-bottom: 5%;
        background-position: center center;
        background-size: 1920px 335px;
        background-repeat: no-repeat;
    }
    .QamdA-item-dwon{
        margin-left: 10%;
        margin-bottom: 20%;
        h3{
            text-align:left;
            font-weight:600;
            color: #007c45;
            font-size: 2.25em;  
        }
        a{
            color: #007c45;
            &:hover{
                color: #74B247;
                text-decoration:underline;
            }
        }
        h4{
            text-align:left;
            font-size: 2em;
            margin-bottom:5%;    
        }
    }
    @media all and (min-width: 480px) and (max-width: 959px) {
        .QamdA-item-img{
            overflow: hidden;
            height: 200px;
        }
        .QamdA-item-dwon{
            margin-left: 5%;
            margin-right: 5%;
            h3{
                text-align:left;
                font-size: 1.75em;
            }
            h4{
                text-align:left;
                font-size: 1.25em;
                margin-bottom:2.5%;    
            }
        }
    }
    @media all and (max-width: 479px) {
        .QamdA-item-img{
            width: 100%;
            overflow: hidden;
            height: 175px;
        }
        .QamdA-item-dwon{
            margin-left:5%;
            margin-right:5%;
            h3{
                font-size: 1.5em;
            }
            h4{
                font-size: 1.15em;
                margin-bottom:2%;    
            }
        }
        
    }
}
